import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  Lang,
  Colors,

  useDimensions,

  Button,
} from '@bluecurrent/web-component-lib';

import Create from '../Create';
import Item from './Item';

export default function Edit(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  const groups = useSelector((state) => state.Groups.groups);

  const [editMode, setEditMode] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [isCreateModeEnabled, setCreateModeEnabled] = React.useState(true);

  React.useEffect(() => {
    if (!edit || editMode) props.setTitle(_('group.groups', { ns: 'ui' }));
    if (edit || editMode) props.setSubTitle('');
    if (edit) props.setTitle(_('group.editGroups', { ns: 'ui' }));
  }, [editMode, edit]);

  React.useEffect(() => {
    setEditMode(size.width >= 1200);
  }, [size]);

  React.useEffect(() => {
    if (isCreateModeEnabled) {
      props.setTitle(_('group.create', { ns: 'ui' }));
      props.setSubTitle('');
    }
  }, [isCreateModeEnabled]);

  return (
    edit ? (
      <Create
        onClose={props.onClose}
        setTitle={props.setTitle}
        setSubTitle={props.setSubTitle}
        data={groups.find((i) => i.group.id === edit)}
        useDeleteGroupDialog={props.useDeleteGroupDialog}
        setDeleteGroupFunc={props.setDeleteGroupFunc}
      />
    ) : isCreateModeEnabled ? (
      <Create
        onClose={props.onClose}
        setTitle={props.setTitle}
        setSubTitle={props.setSubTitle}
        data={props.data}
        useDeleteGroupDialog={props.useDeleteGroupDialog}
        setDeleteGroupFunc={props.setDeleteGroupFunc}
        isCreateMode
      />
    ) : (
      <>
        {
          (size.width < 1200) && (
            <div
              style={{
                height: 'auto',
                width: '100%',
                backgroundColor: Colors.WHITE,
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 7,
                paddingBottom: 7,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 'auto',
                  columnGap: '10px',
                }}
              >
                <Button
                  type="icon"
                  icon={{
                    name: 'Plus',
                    height: 23,
                    width: 23,
                    color: Colors.GREY,
                    iconSet: 'FA',
                  }}
                  colorScheme="white"
                  borderRadius={7}
                  onClick={() => {
                    setCreateModeEnabled(true);
                  }}
                />
                <Button
                  type="icon"
                  icon={{
                    name: editMode ? 'List' : 'Edit',
                    height: 23,
                    width: 23,
                    color: Colors.GREY,
                    iconSet: 'Feather',
                  }}
                  colorScheme="white"
                  borderRadius={7}
                  onClick={() => {
                    if (editMode) setEditMode(false);
                    else setEditMode(true);
                  }}
                />
              </div>
            </div>
          )
        }
        <div
          style={{
            overflowY: 'scroll',
            height: '100%',
            padding: 15,
          }}
        >
          {
            !editMode && (
              <Item
                data={{
                  group: {
                    name: _('allChargePoints', { ns: 'ui' }),
                    icon: {
                      name: 'UMOVE',
                      height: 27,
                      width: 27,
                      color: Colors.GREY,
                      iconSet: 'BlueCurrent',
                    },
                    chargepoints: [
                      _('all', { ns: 'ui' }),
                    ],
                  },
                }}
                edit={false}
                onClick={() => {
                  props.onClickGroup(null, null, null);
                  props.onClose();
                }}
                disable={size.width >= 1200}
              />
            )
          }
          {
            groups.map((item) => (
              <Item
                key={item.group.id}
                data={item}
                edit={editMode}
                onClick={() => {
                  if (editMode) {
                    setEdit(item.group.id);
                  } else {
                    props.onClickGroup(item.group.id, item.group.name, item.group.chargepoints);
                    props.onClose();
                  }
                }}
              />
            ))
          }
        </div>
      </>
    )
  );
}
