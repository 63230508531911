import * as React from 'react';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,
  useSweetName,
  useCurrencyFormat,

  Button,
  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import DataModule from './DataModule';

import ChargingFlow from '../../../../assets/svg/ChargingFlow';

import ElectricityImage from '../../../../assets/svg/Electricity.svg';
import BatteryImage from '../../../../assets/svg/Battery.svg';

import {
  OVERRIDE_DELAYED_CHARGING_TIMEOUT,
  OVERRIDE_CHARGING_PROFILES,
  SET_OPERATIVE,
} from '../../../../api/types/ApiMessages';
import executeCommand from '../../../../api/executeCommand';

import addNotification from '../../../../redux/creators/notifications';

import startSession from '../../../../hooks/commands/startSession';
import stopSession from '../../../../hooks/commands/stopSession';
import softReset from '../../../../hooks/commands/softReset';

export default function ChargePointModule(props) {
  const size = useDimensions();
  const navigate = useNavigate();
  const { _, i18n } = Lang.useTranslation();

  const dispatch = useDispatch();
  const selectedChargePoint = useSelector((state) => state.ChargePoints.find(
    ({ selected }) => selected,
  ) ?? state.ChargePoints[0]);
  const sweetName = useSweetName(_);
  const currencyFormat = useCurrencyFormat();

  const [loadingAvailableButton, setLoadingAvailableButton] = React.useState(false);
  const [loadingErrorButton, setLoadingErrorButton] = React.useState(false);

  const offlineSince = selectedChargePoint.offline_since ? DateTime.fromFormat(selectedChargePoint.offline_since, 'yyyyMMdd TT') : null;

  /*
  available
  charging
  unavailable
  error
  offline

  A = standby
  B = Vehicle detected
  C = Ready (charging)
  D = With ventilation
  E = No power
  F = Error
  */

  let status = 0;
  switch (selectedChargePoint.activity) {
    case 'available':

      if (selectedChargePoint.vehicle_status === 'A') status = 1;
      if (selectedChargePoint.vehicle_status === 'B') status = 3;
      if (selectedChargePoint.vehicle_status === 'F') status = 8;

      break;
    case 'charging':
      if (selectedChargePoint.vehicle_status === 'F') {
        status = 8;
      } else {
        status = 4;
      }
      break;
    case 'unavailable':
      if (selectedChargePoint.vehicle_status === 'F') {
        status = 8;
      } else {
        status = 7;
      }
      break;
    case 'error':
      if (selectedChargePoint.vehicle_status === 'F') {
        status = 8;
      } else {
        status = 7;
      }
      break;
    case 'offline':
      if (selectedChargePoint.vehicle_status === 'F') {
        status = 8;
      } else {
        status = 6;
      }
      break;
    default: break;
  }

  /*
    Status:

    0 = Available - No Cable
    1 = Available - No Car
    2 = Available - Cable
    3 = Available - Car
    4 = Charging
    5 = Offline
    7 = Error
    7 = Unavailable
    8 = Error - Car

    Direction:
    0 = To Car
    1 = To Charge Point

  */

  const calculateAverage = (...stats) => {
    const filtered = stats.filter((i) => i !== 0);
    let count = 0;
    filtered.forEach((i) => {
      count += i;
    });

    if (filtered.length === 0) return 0;
    return count / filtered.length;
  };

  const chargingFlowText = () => {
    if (status === 0) return _('status.available', { ns: 'chargepoint' });
    if (status === 1) return _('status.available', { ns: 'chargepoint' });
    if (status === 2) return _('actions.cableDetected', { ns: 'chargepoint' });
    if (status === 3) return _('actions.carDetected', { ns: 'chargepoint' });
    if (status === 4) return `${calculateAverage(selectedChargePoint.actual_p1, selectedChargePoint.actual_p2, selectedChargePoint.actual_p3).toFixed(0)}/${selectedChargePoint.max_usage} ${_('units.ampere', { ns: 'chargepoint' })}`;
    return '';
  };

  const pressOverrideButton = () => {
    executeCommand(OVERRIDE_DELAYED_CHARGING_TIMEOUT, {
      id: selectedChargePoint.evse_id,
    })
      .then(() => { })
      .catch((error) => {
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        Sentry.captureException(error);
      });
  };

  const pressSessionButton = () => {
    if (selectedChargePoint.activity === 'charging') {
      dispatch({
        type: 'UPDATE_SETTINGS',
        id: selectedChargePoint.evse_id,
        payload: {
          notConfirmedSession: 'STOP_SESSION',
        },
      });
      stopSession(selectedChargePoint.evse_id, _);
    } else {
      startSession(selectedChargePoint.evse_id, _, selectedChargePoint.vehicle_status);
    }
  };
  /* Override Boost Button */
  const pressBoostOverrideButton = () => {
    executeCommand(OVERRIDE_CHARGING_PROFILES, {
      body: {
        boost: true,
      },
    })
      .then(() => {
        addNotification('success', _('settingsSaved', { ns: 'chargepoint' }), 'green');
      })
      .catch((error) => {
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        Sentry.captureException(error);
      });
  };

  React.useEffect(() => {
    try {
      if (
        (selectedChargePoint.notConfirmedSession === 'START_SESSION' && selectedChargePoint.activity === 'charging')
        || (selectedChargePoint.notConfirmedSession === 'STOP_SESSION' && selectedChargePoint.activity !== 'charging')
      ) {
        dispatch({
          type: 'UPDATE_SETTINGS',
          id: selectedChargePoint.evse_id,
          payload: {
            notConfirmedSession: false,
          },
        });
      }
    } catch (e) {
      //
    }
  }, [selectedChargePoint]);

  const messageHandler = () => {
    if (selectedChargePoint.vehicle_status === 'F') {
      return {
        icon: {
          name: 'CarBump',
        },
        text: _('error.vehicleError', { ns: 'chargepoint', model: sweetName(selectedChargePoint.chargepoint_type) }),
      };
    }
    if (selectedChargePoint.activity === 'error') {
      return {
        icon: {
          name: 'Plug',
        },
        text: _('error.error', { ns: 'chargepoint', model: sweetName(selectedChargePoint.chargepoint_type) }),
      };
    }
    if (selectedChargePoint.activity === 'offline') {
      return {
        icon: {
          name: 'Snooze',
        },
        text: _('error.offline', { ns: 'chargepoint', model: sweetName(selectedChargePoint.chargepoint_type) }),
      };
    }
    if (selectedChargePoint.activity === 'unavailable') {
      return {
        icon: {
          name: 'Plug',
        },
        text: _('error.unavailable', { ns: 'chargepoint', model: sweetName(selectedChargePoint.chargepoint_type) }),
      };
    }

    return null;
  };

  /* Format Tariff */
  const renderedCurrencyFormat = currencyFormat(
    selectedChargePoint.tariff.price_ex_vat || 0,
    selectedChargePoint.tariff.currency || 'EUR',
  );

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          height: '80%',
          minHeight: 75,
          width: '100%',
          background: Colors.WHITE,
          overflow: 'hidden',
          borderRadius: 10,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          paddingTop: 5,
          paddingBottom: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: 60,
            marginBottom: 7,
            paddingLeft: 10,
            paddingRight: 10,
            zIndex: 10,
          }}
        >
          <button
            type="button"
            className="Hover"
            onClick={() => {
              props.setSelectChargePointModal();
            }}
            style={{
              marginTop: 7.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: 35,
              fontSize: '1em',
              backgroundColor: 'transparent',
              border: 'solid 0px transparent',
            }}
          >
            {
              selectedChargePoint
                ? (
                  <>
                    <Text
                      fontSize="1.1em"
                      fontWeight={500}
                    >
                      {
                        selectedChargePoint
                          ? `${selectedChargePoint.name || sweetName(selectedChargePoint.chargepoint_type)}`
                          : <Skeleton />
                      }
                    </Text>
                    <span
                      style={{
                        marginLeft: 5,
                        height: 35,
                        display: 'flex',
                        flexDireciton: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon
                        name="AngleDown"
                        color={Colors.GREY}
                        height={25}
                        width={25}
                        iconSet="FA"
                      />
                    </span>
                  </>
                ) : (
                  <Skeleton />
                )
            }
          </button>
        </div>

        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: Colors.WHITE,
            marginBottom: 30,
            position: 'relative',
            border: 'solid 0px transparent',
            fontSize: '1em',
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 0,
              top: -43,
              left: size.width < Breakpoint.fd
                ? -90
                : ((size.width > 576) && (size.width < 680))
                  ? -70
                  : 0,
            }}
          >
            <ChargingFlow
              status={status}
              model={selectedChargePoint.chargepoint_type}
              direction={0}
              current={
                calculateAverage(
                  selectedChargePoint.actual_p1,
                  selectedChargePoint.actual_p2,
                  selectedChargePoint.actual_p3,
                ).toFixed(0)
              }
            />
          </div>
          {
            (
              selectedChargePoint.activity !== 'unavailable'
              && selectedChargePoint.activity !== 'offline'
              && selectedChargePoint.activity !== 'error'
              && selectedChargePoint.vehicle_status !== 'F'
            ) && (
              <div
                style={{
                  position: 'absolute',
                  bottom: -12,
                  width: 'calc(100% - 29px)',
                  marginLeft: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: Colors.WHITE,
                  borderRadius: 10,
                }}
              >
                <Text
                  textAlign="center"
                  fontWeight={500}
                  color={Colors.GREY}
                >
                  {chargingFlowText()}
                </Text>
              </div>
            )
          }
        </div>
      </div>

      {
        (status < 5 && selectedChargePoint.activity !== 'unavailable' && selectedChargePoint.activity !== 'available' && selectedChargePoint.vehicle_status !== 'F') && (
          <div
            style={{
              height: 'auto',
              minHeight: 60,
              width: '100%',
              marginBottom: 10,
              marginTop: -22,
              paddingLeft: 10,
              paddingRight: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <DataModule
              name={_('units.voltage', { ns: 'chargepoint' })}
              value={
                calculateAverage(
                  selectedChargePoint.actual_v1,
                  selectedChargePoint.actual_v2,
                  selectedChargePoint.actual_v3,
                ).toFixed(0)
              }
              unit={_('units.volt', { ns: 'chargepoint' })}
              image={ElectricityImage}
            />
            <DataModule
              name={_('charged', { ns: 'chargepoint' })}
              value={selectedChargePoint.actual_kwh}
              unit={_('units.kwh', { ns: 'chargepoint' })}
              image={BatteryImage}
            />
          </div>
        )
      }
      {
        (status < 5 && selectedChargePoint.activity === 'available' && selectedChargePoint.vehicle_status !== 'F') && (
          <div
            style={{
              height: 'auto',
              minHeight: 60,
              width: '100%',
              marginTop: -22,
              marginBottom: 10,
              paddingLeft: 15,
              paddingRight: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 5,
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              <DataModule
                name={_('currentTariff', { ns: 'finance' })}
                value={selectedChargePoint.tariff.price_ex_vat === 0 ? _('free', { ns: 'ui' }) : renderedCurrencyFormat}
                unit=""
                image={ElectricityImage}
                width="100%"
                onClick={() => navigate(`/chargepoints/${selectedChargePoint.evse_id}`)}
              />
            </div>
          </div>
        )
      }

      {
        /* Delayed Charging */
        selectedChargePoint.activity === 'charging' && selectedChargePoint.vehicle_status !== 'F'
          && selectedChargePoint.delayed_charging.value
          && (
            selectedChargePoint.smart_current_heartbeat_timeout !== null
              ? selectedChargePoint.smart_current_heartbeat_timeout > 0
              : selectedChargePoint.delayed_charging.smart_current_heartbeat_timeout > 0
          )
          ? (
            <div
              style={{
                height: 50,
              }}
            >
              <Button
                text={_('actions.overrideTimeout', { ns: 'chargepoint' })}
                colorScheme="purple-invert"
                disabled={
                      !(selectedChargePoint.vehicle_status === 'B' || selectedChargePoint.vehicle_status === 'C' || selectedChargePoint.vehicle_status === 'D')
                    }
                onClick={pressOverrideButton}
                loading={selectedChargePoint.notConfirmedSession}
              />
            </div>
          )
          : (selectedChargePoint.activity === 'available' || selectedChargePoint.activity === 'charging') && (!selectedChargePoint.plug_and_charge?.value ?? false) && selectedChargePoint.vehicle_status !== 'F' // Start & Stop
            ? (
              <div
                style={{
                  height: size.width > Breakpoint.fd && 50,
                  display: 'flex',
                  flexDirection: size.width > Breakpoint.fd ? 'row' : 'column',
                  justifyContent: size.width > Breakpoint.fd ? 'flex-start' : 'center',
                  gap: size.width < Breakpoint.fd && 5,
                }}
              >
                <Button
                  type="icon"
                  icon={{
                    name: 'Leaf',
                    height: 25,
                    width: 25,
                    color: Colors.WHITE,
                    iconSet: 'FA',
                    hoverColor: selectedChargePoint.vehicle_status === 'C'
                      ? Colors.RED
                      : (selectedChargePoint.vehicle_status === 'A' || selectedChargePoint.vehicle_status === 'B')
                        ? Colors.GREEN : Colors.GREY,
                  }}
                  fontSize="1.3em"
                  hideIcon={!(selectedChargePoint.price_based_charging.value)}
                  fullWidth
                  text={selectedChargePoint.activity === 'charging' ? _('actions.stopSession', { ns: 'chargepoint' }) : _('actions.startSession', { ns: 'chargepoint' })}
                  colorScheme={
                    selectedChargePoint.activity === 'available'
                      ? (selectedChargePoint.vehicle_status === 'B' || selectedChargePoint.vehicle_status === 'C')
                        ? 'green'
                        : 'grey'
                      : selectedChargePoint.activity === 'charging'
                        ? selectedChargePoint.notConfirmedSession
                          ? selectedChargePoint.vehicle_status === 'B' ? 'green' : 'grey'
                          : 'red'
                        : 'grey'
                  }
                  disabled={!(selectedChargePoint.vehicle_status === 'B' || selectedChargePoint.vehicle_status === 'C' || selectedChargePoint.vehicle_status === 'D')}
                  onClick={pressSessionButton}
                  loading={selectedChargePoint.notConfirmedSession}
                />
                {
                  selectedChargePoint.activity !== 'offline' && selectedChargePoint.vehicle_status !== 'F' // Price Based Charging Boost
                  && (selectedChargePoint.price_based_charging.value ?? false)
                  && (
                  <div
                    style={{
                      marginLeft: size.width > Breakpoint.fd && 15,
                    }}
                  >
                    <Button
                      type="icon"
                      icon={{
                        name: 'RocketLaunch',
                        height: 25,
                        width: 25,
                        color: Colors.WHITE,
                        iconSet: 'FA',
                        hoverColor: Colors.BLUE,
                      }}
                      fontSize="1.3em"
                      fullWidth
                      text={_('actions.boost', { ns: 'chargepoint' })}
                      colorScheme={selectedChargePoint.price_based_charging.value && (selectedChargePoint.vehicle_status === 'C') ? 'blue' : 'grey'}
                      onClick={pressBoostOverrideButton}
                      disabled={!(selectedChargePoint.vehicle_status === 'C' || selectedChargePoint.vehicle_status === 'D')}
                    />
                  </div>
                  )
                }
              </div>
            )
            : (
              (selectedChargePoint.activity !== 'offline' && selectedChargePoint.vehicle_status !== 'F') // Plug & Charge
              && (selectedChargePoint.plug_and_charge?.value ?? false)
              && (
                <div
                  style={{
                    height: 50,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 55,
                      backgroundColor: Colors.WHITE,
                      marginTop: -5,
                      border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                      borderRadius: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0,
                    }}
                  >
                    <Text>{_('plugChargeEnabled', { ns: 'chargepoint' })}</Text>
                  </div>
                </div>
              )
            )
      }
      {
        (
          selectedChargePoint.activity === 'unavailable'
          || selectedChargePoint.activity === 'offline'
          || selectedChargePoint.activity === 'error'
          || selectedChargePoint.vehicle_status === 'F'
        ) && (
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              height: 'auto',
              width: '100%',
              marginTop: 10,
              marginBottom: ((selectedChargePoint.activity === 'offline' && offlineSince) || selectedChargePoint.max_offline || selectedChargePoint.vehicle_status === 'F') ? 10 : 0,
            }}
          >
            <div
              style={{
                height: 'auto',
                backgroundColor: Colors.WHITE,
                width: '100%',
                border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                borderRadius: 10,
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                paddingBottom: 15,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  minWidth: 50,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 10,
                  backgroundColor: Colors.LIGHT_WHITE,
                  borderRadius: 10,
                }}
              >
                <Icon
                  name={messageHandler().icon.name}
                  width={30}
                  height={30}
                  color={Colors.DARK_GREY}
                  iconSet="FA"
                />
              </div>
              <Text>
                {messageHandler().text}
              </Text>
            </div>
          </div>
        )
      }

      {
        selectedChargePoint.activity === 'unavailable' && selectedChargePoint.vehicle_status !== 'F' && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Button
              text={_('actions.makeAvailable', { ns: 'chargepoint' })}
              colorScheme="black"
              onClick={() => {
                setLoadingAvailableButton(true);
                executeCommand(SET_OPERATIVE, { evse_id: selectedChargePoint.evse_id })
                  .then(() => {
                    addNotification('success', _('actions.availabilityChanged', { ns: 'chargepoint', id: selectedChargePoint.evse_id }), 'green');
                  })
                  .catch((err) => {
                    Sentry.captureException(err);
                    dispatch(addNotification('failed', _('error.availableFailed', { ns: 'chargepoint', id: selectedChargePoint.evse_id }), 'red'));
                  })
                  .finally(() => setLoadingAvailableButton(false));
              }}
              loading={loadingAvailableButton}
            />
          </div>
        )
      }

      {
        (selectedChargePoint.activity === 'error' || selectedChargePoint.vehicle_status === 'F') && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Button
              text={_('actions.reset', { ns: 'chargepoint' })}
              colorScheme="black"
              onClick={() => {
                setLoadingErrorButton(true);
                softReset(selectedChargePoint.evse_id, _, () => setLoadingErrorButton(false));
              }}
              loading={loadingErrorButton}
            />
          </div>
        )
      }

      {
        selectedChargePoint.activity === 'offline'
        && (
          offlineSince
          || selectedChargePoint.max_offline
        ) && (
          <div
            style={{
              width: '100%',
              height: 'auto',
              border: `solid 2px ${Colors.MEDIUM_WHITE}`,
              borderRadius: 10,
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: Colors.WHITE,
            }}
          >
            <div
              style={{
                borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingBottom: 10,
              }}
            >
              <Text
                fontSize="1em"
                color={Colors.DARK_GREY}
                fontWeight={500}
              >
                {`${_('additionalInformation', { ns: 'ui' })}:`}
              </Text>
            </div>
            {
              offlineSince && (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Text
                    fontSize="1.1em"
                  >
                    {`${_('offline.lastOnline', { ns: 'chargepoint' })}: ${offlineSince && offlineSince.setLocale(i18n.language).toRelative()}`}
                  </Text>
                </div>
              )
            }
            {
              selectedChargePoint.max_offline && (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Text
                    fontSize="1.1em"
                  >
                    {`${_('offline.maxOfflineCharging', { ns: 'chargepoint' })}: ${selectedChargePoint.max_offline} ${_('units.ampere', { ns: 'chargepoint' })}`}
                  </Text>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}
