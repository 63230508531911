import React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Item,
  Text,
} from '@bluecurrent/web-component-lib';
import executeCommand from '../../../../api/executeCommand';
import addNotification from '../../../../redux/creators/notifications';

import PriceBasedCharging from './ProfiledChargingProfiles/PriceBasedCharging';
import DelayedCharging from './ProfiledChargingProfiles/DelayedCharging';
import SolarBasedCharging from './ProfiledChargingProfiles/SolarBasedCharging';

import ProfiledChargingSettings from '../../../../config/ProfiledCharging.config';

import useChargingProfile from '../../../../hooks/useChargingProfile';

export default function ProfiledChargingSetting(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();

  const chargingProfile = useChargingProfile(props.evseSettings);

  const [isSwitchValue, setSwitchValue] = React.useState(chargingProfile);

  const hasPermission = (
    props.evseSettings.delayed_charging.permission === 'write'
    || props.evseSettings.delayed_charging.permission === 'read'
    || props.evseSettings.price_based_charging.permission === 'write'
    || props.evseSettings.price_based_charging.permission === 'read'
    || props.evseSettings.solar_based_charging.permission === 'write'
    || props.evseSettings.solar_based_charging.permission === 'read'
  );

  const onSwitch = () => {
    /* Open Change profile */
    if (!isSwitchValue) {
      props.setProfiledChargingModal(true);
    } else {
    /* executeCommand disable current profile */
      executeCommand(
        ProfiledChargingSettings[chargingProfile].apiMessage,
        {
          body: {
            evse_id: props.evseSettings.evse_id,
            value: false,
          },
        },
      ).then(() => {
        dispatch({
          type: 'UPDATE_SETTINGS',
          id: props.evseSettings.evse_id,
          payload: {
            [chargingProfile]: {
              ...props.evseSettings[chargingProfile],
              value: false,
            },
          },
        });
      })
        .catch((err) => {
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
          Sentry.captureException(err);
        });
    }

    setSwitchValue(!isSwitchValue);
  };

  const displaySetting = () => {
    /* Switch case based on the results of useChargingProfile */
    switch (chargingProfile) {
      case 'price_based_charging':
        return (
          <PriceBasedCharging
            evseSettings={props.evseSettings}
            isDisabled={props.evseSettings.price_based_charging.permission !== 'write'}
          />
        );
      case 'delayed_charging':
        return (
          <DelayedCharging
            evseSettings={props.evseSettings}
            isDisabled={props.evseSettings.delayed_charging.permission !== 'write'}
          />
        );
      case 'solar_based_charging':
        return (
          <SolarBasedCharging
            evseSettings={props.evseSettings}
            isDisabled={props.evseSettings.solar_based_charging.permission !== 'write'}
          />
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (chargingProfile !== null) return setSwitchValue(true);

    return null;
  }, [chargingProfile]);

  if (!hasPermission) return null;

  return (
    <>
      <Text
        fontSize="1em"
        color={Colors.DARK_GREY}
        fontWeight={500}
      >
        {_('chargingProfiles.name', { ns: 'chargepoint' })}
      </Text>
      <div
        className="DisableSelection"
        style={{
          padding: 10,
          height: 'auto',
          minHeight: 55,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          paddingTop: 10,
          marginTop: 10,
        }}
      >
        <Item
          type="boolean"
          title={_('chargingProfiles.profiledCharging', { ns: 'chargepoint' })}
          value={isSwitchValue}
          noPermission={!hasPermission}
          tooltip={{
            error: _('failedToLoadSetting', { ns: 'settings' }),
            permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
          }}
          onSwitch={onSwitch}
        />

        {
          isSwitchValue && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                marginTop: 10,
                paddingTop: 10,
                minHeight: 45,
              }}
            >
              <Item
                type="button"
                title={_('changeProfile', { ns: 'ui' })}
                suffix={
                  chargingProfile
                  && _(
                    ProfiledChargingSettings[chargingProfile].titleTranslation[0],
                    { ns: ProfiledChargingSettings[chargingProfile].titleTranslation[1] },
                  )
                }
                onClick={() => props.setProfiledChargingModal(true)}
                noPermission={!hasPermission}
                tooltip={{
                  error: _('failedToLoadSetting', { ns: 'settings' }),
                  permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
                }}
              />
            </div>
          )
        }
      </div>
      {
        isSwitchValue && (
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              flexDirection: 'column',
              gap: 7.5,
            }}
          >
            {
              displaySetting()
            }
          </div>
        )
      }
    </>
  );
}
