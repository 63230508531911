import * as Sentry from '@sentry/browser';

import store from '../../redux/store';
import addNotification from '../../redux/creators/notifications';

import executeCommand from '../../api/executeCommand';
import {
  START_SESSION,
} from '../../api/types/ApiMessages';

import DefaultChargeCard from '../../config/DefaultChargeCard.config';

const startSessionHandler = (evseId, _, uid) => {
  store.dispatch({
    type: 'OVERLAY',
    payload: {
      display: false,
      layout: 'chargecards',
      title: false,
      subTitle: false,
    },
  });

  store.dispatch(
    addNotification(
      'info',
      _('updates.startCharging', { ns: 'chargepoint', id: evseId }),
      'blue',
    ),
  );
  store.dispatch({
    type: 'UPDATE_SETTINGS',
    id: evseId,
    payload: {
      notConfirmedSession: 'START_SESSION',
    },
  });

  executeCommand(START_SESSION, { evse_id: evseId, session_token: uid })
    .then(() => {
      store.dispatch(
        addNotification(
          'success',
          _('updates.startChargingSuccess', { ns: 'chargepoint', id: evseId }),
          'green',
        ),
      );
    })
    .catch((err) => {
      Sentry.captureException(err);
      store.dispatch(
        addNotification(
          'failed',
          _('error.startChargingFailed', { ns: 'chargepoint' }),
          'red',
        ),
      );
    });
};

const startSession = (evseId, _, vehicleStatus) => {
  if (vehicleStatus !== 'B' && vehicleStatus !== 'C') {
    store.dispatch(
      addNotification(
        'failed',
        _('error.vehicleNotFound', { ns: 'chargepoint', id: evseId }),
        'red',
      ),
    );
    return;
  }

  const data = [...store.getState().ChargeCards.chargeCards];
  data.unshift({
    name: _(...DefaultChargeCard.NAME),
    id: DefaultChargeCard.ID,
    uid: DefaultChargeCard.UID,
  });

  store.dispatch({
    type: 'OVERLAY',
    payload: {
      display: true,
      layout: 'overlaySearch',
      data: data.map(({
        name,
        id,
        uid,
      }) => ({
        id,
        name,
        type: 'chargecard',
        serial: id,
        onClick: () => {
          startSessionHandler(evseId, _, uid);
        },
      })),
      title: _('chargeCards', { ns: 'ui' }),
      subTitle: false,
    },
  });
};

export default startSession;
