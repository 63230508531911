import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Text,
  Input,
  Button,
  OverlaySearch,

  Lang,
  Colors,
} from '@bluecurrent/web-component-lib';

import {
  ADD_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
} from '../../../../api/types/ApiMessages';
import executeCommand from '../../../../api/executeCommand';

import addNotification from '../../../../redux/creators/notifications';

import symbols from '../../Groups/icons';

export default function Groups(props) {
  const { _ } = Lang.useTranslation();
  const chargePoints = useSelector((state) => state.ChargePoints);
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [name, setName] = React.useState(props.data?.group?.name ?? '');
  const [symbol, setSymbol] = React.useState(props.data?.group?.icon?.name);
  const [evses, setEvses] = React.useState(
    props.data?.group?.chargepoints ?? [],
  );

  const [, setDeleteGroupDialog] = props.useDeleteGroupDialog ?? [null, null];

  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  React.useEffect(() => {
    props.setTitle(props.isCreateMode ? _('group.create', { ns: 'ui' }) : name);
  }, []);

  React.useEffect(() => {
    if (page === 1) {
      props.setSubTitle(props.isCreateMode ? '' : _('group.edit', { ns: 'ui' }));
      if (!props.isCreateMode) {
        props.setTitle(name);
      }
    }
    if (page === 2) {
      props.setTitle(name);
      props.setSubTitle(props.isCreateMode ? _('group.create', { ns: 'ui' }) : _('group.edit', { ns: 'ui' }));
    }
  }, [page, name]);

  const createGroup = () => {
    setLoading(true);
    const icon = symbols.find((data) => data.name === symbol);
    executeCommand(ADD_GROUP, {
      body: {
        name,
        icon: {
          name: icon.name,
          width: icon.width,
          height: icon.height,
        },
        chargepoints: evses,
      },
    })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'ADD_GROUP',
            payload: {
              group: {
                id: res.data.result.group_id,
                name,
                icon: {
                  name: icon.name,
                  width: icon.width,
                  height: icon.height,
                },
                chargepoints: evses,
              },
            },
          });
          props.onClose();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  const updateGroup = () => {
    setLoading(true);
    const icon = symbols.find((data) => data.name === symbol);
    executeCommand(EDIT_GROUP, {
      body: {
        name,
        id: props.data.group.id,
        icon: {
          name: icon.name,
          width: icon.width,
          height: icon.height,
        },
        chargepoints: evses,
      },
    })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'UPDATE_GROUP',
            id: props.data.group.id,
            payload: {
              id: props.data.group.id,
              name,
              icon: {
                name: icon.name,
                width: icon.width,
                height: icon.height,
              },
              chargepoints: evses,
            },
          });
          props.onClose();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  const deleteGroup = (id) => {
    setDeleteLoading(true);
    executeCommand(DELETE_GROUP, { body: { id } })
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'DELETE_GROUP',
            id,
          });
          props.onClose();
        } else {
          Sentry.captureException(res.data);
          setLoading(false);
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        setLoading(false);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  React.useEffect(() => {
    if (props.data) props.setDeleteGroupFunc(deleteGroup);
  }, []);

  return (
    <>
      {
        page === 1 && (
          <div
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              overflowY: 'scroll',
              height: '100%',
              paddingTop: 30,
            }}
          >
            <Input
              type="text"
              label={_('name', { ns: 'ui' })}
              name="group-name"
              placeholder={_('garage', { ns: 'ui' })}
              styling
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div
              style={{
                marginTop: 50,
              }}
            >
              <Text
                fontSize="1em"
                fontWeight={500}
              >
                {_('symbol', { ns: 'ui' })}
              </Text>
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginBottom: 30,
                }}
              >
                {
                  symbols.map(({ name: iconName, width, height }, index) => (
                    <div
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      style={{
                        marginRight: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Button
                        type="icon"
                        icon={{
                          name: iconName,
                          width,
                          height,
                          color: symbol === iconName ? Colors.WHITE : Colors.GREY,
                          iconSet: 'FA',
                        }}
                        border={symbol === iconName ? `2px solid ${Colors.BLUE}` : undefined}
                        onClick={() => setSymbol(iconName)}
                        styling
                        colorScheme={symbol === iconName ? 'blue' : 'white'}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
      {
        page === 2 && (
          <OverlaySearch
            onClose={props.onClose}
            selectAll={
              evses.length !== chargePoints.length
                ? () => setEvses(
                  chargePoints.map((i) => i.evse_id),
                )
                : false
            }
            deselectAll={
              evses.length === chargePoints.length
                ? () => setEvses([])
                : false
            }
            data={chargePoints.map((i) => ({
              name: i.name,
              serial: i.evse_id,
              type: 'chargepoint',
              selected: evses.includes(i.evse_id),
              icon: {
                name: 'UMOVE',
                height: 50,
                width: 50,
                color: Colors.GREY,
                iconSet: 'BlueCurrent',
              },
              onClick: () => {
                if (evses.includes(i.evse_id)) {
                  setEvses((prev) => prev.filter((x) => x !== i.evse_id));
                } else {
                  setEvses((prev) => [...prev, i.evse_id]);
                }
              },
            }))}
          />
        )
      }
      <div
        style={{
          paddingTop: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: page > 1 ? 'space-between' : props.data ? 'space-between' : 'flex-end',
          paddingLeft: 15,
          paddingRight: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          marginTop: -2,
          position: 'relative',
          zIndex: 3,
          backgroundColor: Colors.WHITE,
        }}
      >
        {
          (page === 1 && props.data) && (
            <div
              style={{
                width: 'auto',
              }}
            >
              {
                props.data.group && (
                  <Button
                    type="icon"
                    colorScheme={deleteLoading ? 'grey' : 'white'}
                    onClick={() => setDeleteGroupDialog(props.data.group.id)}
                    loading={deleteLoading}
                    disabled={deleteLoading}
                    icon={{
                      name: 'Bin',
                      height: 20,
                      width: 20,
                      color: Colors.RED,
                      iconSet: 'FA',
                    }}
                    border={`2px solid ${Colors.RED}`}
                  />
                )
              }
            </div>
          )
        }
        {
          page > 1 && (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                text={_('back', { ns: 'ui' })}
                colorScheme="white"
                onClick={() => setPage(page - 1)}
              />
            </div>
          )
        }
        <div
          style={{
            width: 'auto',
            marginBottom: 15,
          }}
        >
          {
            page === 1 && (
              <Button
                disabled={!name || !symbol ? { color: Colors.MEDIUM_WHITE } : undefined}
                text={_('continue', { ns: 'ui' })}
                onClick={() => setPage(page + 1)}
                colorScheme={!name || !symbol ? 'grey' : 'blue'}
              />
            )
          }

          {
            page === 2 && (
              <Button
                type="submit"
                text={props.isCreateMode ? _('group.create', { ns: 'ui' }) : _('group.update', { ns: 'ui' })}
                loading={loading}
                onClick={props.isCreateMode ? createGroup : updateGroup}
                disabled={evses.length === 0}
                colorScheme={evses.length === 0 ? 'grey' : 'blue'}
              />
            )
          }
        </div>
      </div>
    </>
  );
}
