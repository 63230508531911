import * as React from 'react';

import {
  Colors,
  Breakpoint,

  truncate,
  useDimensions,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function Item(props) {
  const size = useDimensions();

  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className={props.disable ? 'Disabled' : 'Hover'}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.disable ? null : props.onClick}
      style={{
        height: 70,
        width: '100%',
        backgroundColor: !props.disable && hover ? Colors.LIGHT_WHITE : Colors.WHITE,
        border: `${props.edit ? 'dashed' : 'solid'} 2px ${!props.disable && hover ? Colors.MEDIUM_GREY : Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        padding: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 15,
        fontSize: '1em',
      }}
    >
      <div
        style={{
          width: 45,
          minWidth: 45,
          height: 45,
          backgroundColor: Colors.WHITE,
          marginRight: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          border: `solid 2px ${!props.disable && hover ? Colors.MEDIUM_GREY : Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
        }}
      >
        <Icon
          name={props.data.group.icon.name}
          height={props.data.group.icon.height}
          width={props.data.group.icon.width}
          iconSet={props.data.group.icon.iconSet ?? 'FA'}
          color={Colors.GREY}
        />
      </div>
      <div
        style={{
          height: 'auto',
        }}
      >
        <Text
          fontWeight={500}
        >
          {truncate(props.data.group.name, size.width < Breakpoint.fd ? 11 : 30)}
        </Text>
        <div
          style={{
            paddingTop: 3,
          }}
        >
          <Text
            fontSize="0.9em"
            color={Colors.MEDIUM_GREY}
          >
            {
              props.data.group.chargepoints.length < 1 ? '' : truncate(`${props.data.group.chargepoints[0]} ${props.data.group.chargepoints[1] ? `, ${props.data.group.chargepoints[1]}` : ''}`, size.width < Breakpoint.fd ? 13 : 18)
            }
          </Text>
        </div>
      </div>
    </button>
  );
}
